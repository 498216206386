import React, { useState } from 'react';
import {
  Button,
  Col,
  Container,
  FormControl,
  Image,
  InputGroup,
  Row,
  Form,
  Modal,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  base_url,
  currency,
  currencycode,
  helmet_url,
  postHeader,
} from '../../../../Helpers/request';
import axios from 'axios';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';

function NewsLetter() {
  const history = useHistory();

  const [newsletteremail, setNewsletteremail] = useState('');
  const [loader, setLoader] = useState(false);
  const newsletter = (e) => {
    e.preventDefault();
    setLoader(true);
    const data = {
      currency_code: currencycode,
      email: newsletteremail,
    };
    axios
      .post(base_url + '/common/subscribe_newsletter', data, {
        headers: postHeader,
      })
      .then((res) => {
        if (res.data.status == 1) {
          setLoader(false);
          setNewsletteremail('');
          return swal(res.data.message, '', 'success');
        } else {
          setLoader(false);
          return swal(res.data.message, '', 'error');
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };

  return (
    <>
      <section className="Lt_NewsLetter_section">
        <Container>
          <Row className="m-0 w-100">
            <Col lg={6}>
              <div className="Lt_NewsLetter_content">
                <h2> Join the list </h2>
                <p>
                  Become a preferred subscriber and get a special offer on your
                  purchase.
                </p>

                <div className="rcs_signup_to_input_field">
                  <Form className="gs_news_form w-100" onSubmit={newsletter}>
                    <InputGroup className="mb-3">
                      <FormControl
                        placeholder="Email Address..."
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        type="email"
                        value={newsletteremail}
                        required
                        onChange={(e) => setNewsletteremail(e.target.value)}
                      />
                      <Button
                        variant="outline-secondary"
                        type="submit"
                        id="button-addon2"
                      >
                        Subscribe
                      </Button>
                    </InputGroup>
                  </Form>
                </div>
              </div>
            </Col>
            <Col lg={6} className="Lt_NewsLetter_border_left">
              <div className="Lt_NewsLetter_content ">
                <h2> Need Help? </h2>
                <p>
                  Call us{' '}
                  <Link
                    to="#"
                    onClick={(e) => {
                      window.location.href = `tel:+5617379595`;
                    }}
                  >
                    {' '}
                    561-737-9595{' '}
                  </Link> |
                  Tue To Fri : 10am - 5:30pm <br /> Sat : 10am - 2pm | Email us
                  info@brillantejewelers.com
                </p>

                <Button
                  className="london_banner_btn_fill"
                  onClick={() => {
                    history.push('/make-an-appointment', '_blank');
                  }}
                >
                  {' '}
                  Make An Enquiry{' '}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default NewsLetter;
